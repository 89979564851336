/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Slides } from '../data';

export default function HomeSlider() {
  const [index, setIndex] = useState(0);

  const handleSelect = selectedIndex => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className="home-slider">
      {Slides.map(slide => (
        <Carousel.Item key={slide.id}>
          <img
            className="d-block w-100"
            src={slide.image}
            alt={slide.alt}
            loading="lazy"
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
