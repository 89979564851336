import PageLayout from '../components/page';

const CareerPage = () => (
  <PageLayout title="Careers">

    <h4>Careers</h4>
    <p>
      If you are dynamic, like to take responsibility
      in your career, respect human relations and
      want to be a part of a growing company atmosphere,
    </p>

    <p>
      It will be the best choice that you will make.
    </p>
    <h5>Our Values;</h5>

    <ul>
      <li>&raquo; Honesty</li>
      <li>&raquo; Dedication</li>
      <li>&raquo; Hard working</li>
      <li>&raquo; Quality</li>
      <li>&raquo; Ambition</li>
      <li>&raquo; To be the best in every discipline</li>
    </ul>

    <p>
      Visit our other
      <a style={{ color: '#ff6600' }} href="http://www.kariyer.net/is-ilanlari/#&amp;kw=vks mühendislik" target="_blank" rel="noopener noreferrer"> our other career page </a>
      to see our job roles and
      position to aid your application

    </p>
  </PageLayout>
);

export default CareerPage;
