import malePlaceholder from '../images/team-male-placeholder.png';
import seun from '../images/seun.jpg';
import onur from '../images/onur-kumral.jpg';
import onye from '../images/onyekachi.jpg';
import ndiana from '../images/ndiana.jpg';
import ekpo from '../images/ekpo.jpg';
// import femalePlaceholder from '../images/team-female-placeholder.jpeg';

const MANAGEMENT_TEAM = [
  {
    id: 1,
    image: malePlaceholder,
    name: 'Oliver Ebong',
    position: 'Chairman',
  },
  {
    id: 2,
    image: onur,
    name: 'Onur Kumral',
    position: 'Managing Director',
  },
  {
    id: 3,
    image: malePlaceholder,
    name: 'Hakan Aslan',
    position: 'Chief of Operations',
  },
  {
    id: 4,
    image: ndiana,
    name: 'Ndiana Isong',
    position: 'Group HR Manager',
  },
  {
    id: 5,
    image: malePlaceholder,
    name: 'Candas Ustamehmetoglu',
    position: 'IT manager',
  },
  {
    id: 6,
    image: onye,
    name: 'Onyekachi Ashikodi',
    position: 'QAQC Manager',
  },
  {
    id: 7,
    image: malePlaceholder,
    name: 'Obinna Onwuka',
    position: 'Health and Safety Manager',
  },
  {
    id: 8,
    image: ekpo,
    name: 'Akpabong Ekpo',
    position: 'Group Account Manager',
  },
  {
    id: 9,
    image: malePlaceholder,
    name: 'Senol Coban',
    position: 'Administrative Affairs Chief',
  },
  {
    id: 10,
    image: seun,
    name: 'Seun Ogunlesi',
    position: 'Group Logistics/Procurement Manager',
  },
  {
    id: 11,
    image: malePlaceholder,
    name: 'Inimfon Obot',
    position: 'Civil Manager',
  },
  {
    id: 12,
    image: malePlaceholder,
    name: 'Serhat Aydin',
    position: 'Finance Manager',
  },
  {
    id: 13,
    image: malePlaceholder,
    name: 'Namadi Ekong',
    position: 'Electrical Manager',
  },
];

export default MANAGEMENT_TEAM;
