import ManagementTeam from '../components/management-team';
import PageLayout from '../components/page';
import MANAGEMENT_TEAM from '../data/Management';

const ManagementPage = () => (
  <PageLayout title="Management Team">
    <h1>Management Team</h1>
    <ManagementTeam data={MANAGEMENT_TEAM} />
  </PageLayout>
);

export default ManagementPage;
