/* eslint-disable max-len */
import React from 'react';
import {
  Container,
  Navbar,
  NavDropdown,
  Nav,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { NavDropdownMenu } from 'react-bootstrap-submenu';
import '../styles/purpose.css';
import logo from '../images/logo.png';

export default function MainNav() {
  return (
    <header className="header sticky-top" id="header-main">
      <Navbar
        collapseOnSelect
        expand="lg"
        className="navbar-main navbar-transparent
      navbar-dark bg-dark"
        id="navbar-main"
      >
        <Container>
          <NavLink to="/" className="navbar-brand" role="button">
            <img src={logo} className="logo" alt="logo" style={{ height: '60px', width: '87px' }} loading="lazy" />
          </NavLink>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto align-items-lg-center">
              <NavLink to="/" className="nav-link" role="button">Home</NavLink>
              <NavDropdownMenu title="About Us" id="collasible-nav-dropdown" className="dropdown-animate" data-toggle="hover">
                <NavLink to="/about" className="dropdown-item" role="button">Company Profile</NavLink>
                <NavLink to="/mission" className="dropdown-item" role="button">Mission, Vision &amp; Values</NavLink>
                <NavDropdown.Item href="/portfolio">Portfolio</NavDropdown.Item>
                <NavLink to="/ethics" className="dropdown-item" role="button">Ethics &amp; Compliance</NavLink>
                <NavLink to="/offices" className="dropdown-item" role="button">Our Offices</NavLink>
              </NavDropdownMenu>
              <NavDropdownMenu title="Projects" id="collasible-nav-dropdown">
                <NavLink to="/completed-projects" className="dropdown-item" role="button">Completed</NavLink>
                <NavLink to="/ongoing-projects" className="dropdown-item" role="button">Ongoing</NavLink>
              </NavDropdownMenu>
              <NavDropdownMenu title="Social Responsibility" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#/">VKS Foundation</NavDropdown.Item>
              </NavDropdownMenu>
              <NavLink to="/news" className="nav-link" role="button">News</NavLink>
            </Nav>

            {/* <Nav className="mr-auto align-items-lg-center">
              <Nav.Link href="#/" className="btn btn-sm btn-white btn-icon rounded-pill d-none d-lg-inline-flex">
                <span className="btn-inner--text">Login</span>
              </Nav.Link>
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
