import React from 'react';
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { topMenuLinks, socialIcons } from '../data';

export default function TopNav() {
  return (
    <div className="top-menu pt-2 pb-2">
      <Container>
        <nav className="nav d-flex justify-content-between">
          <div className="icons d-flex">
            { socialIcons.map(icon => (
              <a
                key={icon.title}
                target="_blank"
                rel="noreferrer"
                className="pr-3"
                href={icon.url}
              >
                {icon.name}
              </a>
            )) }
          </div>
          <div className="menu">
            {topMenuLinks.map(menuLink => (
              <NavLink
                whileHover={{
                  scale: 1.3,
                  originX: 0,
                  color: '#24262b',
                }}
                transition={{ type: 'spring', stiffness: 300 }}
                to={menuLink.url}
                className={`${menuLink.extra_class} menu__links`}
                role="button"
                key={menuLink.id}
              >
                {menuLink.title}

              </NavLink>
            ))}
          </div>
        </nav>
      </Container>
    </div>
  );
}
