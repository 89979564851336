import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PageHelment from '../components/helmet';
import { getMonthDiff } from '../components/utils';
import projects from '../data/Projects';

function ProjectDescription() {
  const { slug } = useParams();
  const [projectItem] = projects.filter(projects => projects.slug === slug);
  return (
    <>
      {projectItem.cover ? <div className="cover-img" style={{ height: '300px', background: `url(${projectItem.cover}) no-repeat center center`, backgroundSize: 'cover' }} /> : null}

      <div className="page about-section">
        <PageHelment>
          {projectItem.title}
        </PageHelment>
        <Container className="page-space">
          <h1>{projectItem.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: projectItem.description }} />
          {/* <p>{projectItem.description}</p> */}
          <h4>Project Duration</h4>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Duration</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{projectItem.start_date}</td>
                <td>{projectItem.end_date}</td>
                <td>{getMonthDiff(projectItem.start_date, projectItem.end_date)}</td>
                <td>{projectItem.status}</td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </div>
    </>
  );
}

export default ProjectDescription;
