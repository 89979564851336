/* eslint-disable react/prop-types */
import { Helmet } from 'react-helmet';

export default function PageHelment({ children }) {
  return (
    <Helmet>
      <title>{children}</title>
    </Helmet>
  );
}
