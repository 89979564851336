import PageLayout from '../components/page';

const EthicsPage = () => (
  <PageLayout title="Ethics and Compliance">
    <h4>Ethics and Compliance</h4>
    <p>
      VKS Engineering &amp; Construction CO. has established,
      documented, implemented, and maintained a Quality
      Management System to ensure the effectiveness of the
      business and to meet the needs &amp; expectations of its
      customers in the projects undertaken. VKS Construction’s
      Quality Management System is compatible with ISO 9001,
      14001:2004, 18001:2007.
    </p>

    <p>
      In all VKS Group Projects, Quality Management
      System is structured on the basis of VKS Corporate
      Quality Management System and projects’ contract requirements.

    </p>

    <p>
      Quality Management System of projects provides an
      effective means of ensuring that services and products
      provided by VKS; conform to prime contract requirements.
      Quality Management System of projects places a heavy
      emphasis on the prevention of problems rather than a
      dependence on detection, once problems have arisen.
    </p>
  </PageLayout>
);

export default EthicsPage;
