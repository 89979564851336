import PageLayout from '../components/page';

const PrivacyPage = () => (
  <PageLayout title="Privacy Policy">
    <h4>Privacy</h4>
    <p>
      VKS recognizes and respects the privacy of users of this Website.
      When you choose to provide us with information about yourself,
      VKS will act in a responsible manner. VKS may store and disclose
      personal information as allowed or required by applicable law,
      including making disclosures that are necessary or advisable to
      (i) protect the rights, safety or property of VKS or others and
      (b) conform to legal or regulatory requirements. Without your
      express approval, however, VKS will not trade, sell or rent your
      personal information but may collect and/or provide aggregate
      statistics about its Website and users to other parties.
      VKS has no intention of collecting any personal data from individuals
      under 18 years of age. When you view certain parts of the Website,
      information may be stored on your computer. This information will
      be in the form of a “Cookie” or similar file and will permit
      tailoring of the Website to better match your interests and/or
      preferences. This site, however, contains links to other Websites
      and VKS does not control the privacy policies of those sites.
      Please also find Linking below. This privacy statement only applies
      to information collected and maintained by VKS.
    </p>

    <h4>Linking</h4>
    <p>
      As a convenience, this Website contains links to other sites
      that are not controlled by, or affiliated or associated with,
      VKS. Accordingly, VKS does not make any representations
      concerning the privacy practices or terms of use of such sites,
      nor does VKS control or guarantee the accuracy, integrity, or
      quality of the information, data, text, software, music, sound,
      photographs, graphics, video, messages or other materials
      available on such sites. The inclusion or exclusion does not
      imply any endorsement by VKS of the site, the site’s provider,
      or the information on the site.
    </p>

  </PageLayout>
);

export default PrivacyPage;
