import { Card } from 'react-bootstrap';

// eslint-disable-next-line react/prop-types
export default function ContactCard({ image, title, text }) {
  return (
    <Card className="card-shadow">
      <Card.Img variant="top" src={image} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>
          {text}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
