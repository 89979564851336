import React from 'react';
import { Container } from 'react-bootstrap';
import { Projects } from '../data';
import FeaturedProjectItem from '../components/FeaturedProjectItem';
import PageHelment from '../components/helmet';

const completedProjects = Projects.filter(projects => projects.completed);

const CompletedProjectsPage = () => (
  <section className="projects-section">
    <PageHelment>Completed Projects - VKS Nigeria Construction Ltd.</PageHelment>
    <Container className="page-space">
      <h2>Completed Projects</h2>
      <div className="projects-thumbnails">
        <ul className="latest-projects">
          { completedProjects.map(project => (
            <FeaturedProjectItem
              key={project.id}
              title={project.title}
              image={project.image}
              url={project.slug}
              alt={project.alt}
            />
          )) }
        </ul>
      </div>
    </Container>
  </section>
);

export default CompletedProjectsPage;
