/* eslint-disable react/prop-types */
import parse from 'html-react-parser';

export default function NewsList({ title, ...rest }) {
  const {
    url, excerpt, source, date, author, image, alt, content,
  } = rest;

  return (

    <article className="my-5">
      <h1 className="text-danger mb-1">{title}</h1>
      <h5 className="font-weight-lighter">
        <em>{excerpt}</em>
      </h5>

      <div className="small my-4">
        <span>
          <em>Source: </em>
          <a href={url} target="_blank" rel="noopener noreferrer" className="text-success">
            {source}
          </a>
        </span>
        <br />
        {`${author && `By ${author} -`} ${date}`}
      </div>
      <img src={image} alt={alt} className="p-2 w-50 float-left" />

      {parse(content)}
      ...
      <a href={url} target="_blank" rel="noopener noreferrer" className="text-success">readmore</a>

      <hr />
    </article>

  );
}
