import airportImg from '../images/airport_project.jpg';
import coconut from '../images/coconut.jpg';
import odiokwu from '../images/ph1-270x240.jpg';
import kfm from '../images/kingsflourmill.jpg';
import phase3 from '../images/vks_phaseIII.jpg';
import syringe from '../images/syringe_office.jpg';
import tower from '../images/tower_gal.jpg';
import phase1 from '../images/phase1.jpg';
import phase2 from '../images/vks_phaseII_3.jpeg';
import jsmCover from '../images/cover/jsm_cover.jpg';
import kfmCover from '../images/cover/kfm_cover.jpg';
import towerCover from '../images/cover/tower_cover.jpg';
import coconutCover from '../images/cover/coconut_cover.jpg';
import airportCover from '../images/cover/airport_cover.jpg';
import phase3Cover from '../images/cover/phase3_cover.jpg';
import phase2Cover from '../images/cover/phase2_cover.jpg';
import phase1Cover from '../images/cover/phase1_cover.jpg';
import hotelfcCover from '../images/cover/hotelfc_cover.jpg';
import hotelPhc from '../images/hotelphc.jpg';

const Projects = [
  {
    id: 1,
    title: 'Victor Attah International Airport Project',
    image: airportImg,
    alt: 'Airport Project',
    url: '#airport',
    completed: false,
    feature: true,
    description: `<p>The proposed project is the development of the Victor Attah International Airport, an international smart terminal building with cutting-edge technologies and modern facilities. The airport will feature automated luggage screening and weighing, video/scene analytics, foot traffic analysis, facial recognition, license plate readers, and incident analysis, among others.</p>

    <p>The building will be designed with moisture control technology for hot seasons and will utilize new technologies involving synchronized motors, regeneration converters, and optimized counterweights to yield significant energy savings. The terminal building will also feature daylighting and advanced occupancy and dimming functions to eliminate over-lit spaces.</p>
    
    <p>To ensure seamless operations, the airport will feature intelligent transport management systems, a fully integrated alarm and voice evacuation system, and an infrared toilet system with an automatic flushing system. Additionally, the airport will automate immigration processes and centers, providing a hassle-free experience for travelers.</p>
    
    <p>Overall, the Victor Attah International Airport will be a world-class airport that leverages the latest technologies and best practices to deliver exceptional services to travelers.</p>`,
    image_gallery: [],
    start_date: 'July, 2019',
    end_date: 'May, 2021',
    status: 'On-going',
    slug: 'victor-attah-international-airport-project',
    cover: airportCover,
  },
  {
    id: 2,
    title: 'Kings Flour Mill Factory Project',
    image: kfm,
    alt: 'Kings Flour Mill',
    url: '#kfm',
    completed: true,
    feature: true,
    description: `<h4>Project Overview:</h4>
    <p>Kings Flour Mill is a factory that specializes in processing and packaging high-quality wheat-based flour. The project involves building and installing state-of-the-art machines for the processing and packaging of the flour. The factory's production capacity is 500 metric tons per day, which will be achieved through the use of modern technology and efficient processes.</p>
    
    <h4>Location:</h4>
    <p>The Kings Flour Mill is located in Onna LGA, Akwa Ibom State, Nigeria. The location was chosen due to its strategic position and accessibility to major highways, allowing for easy distribution of the finished products.</p>
    
    <h4>Project Scope:</h4>
    <p>The Kings Flour Mill project will involve the construction of the factory from the ground up, including the installation of state-of-the-art machines for processing and packaging high-quality wheat-based flour. The project will also include the installation of power generators, water treatment plants, and waste management systems.</p>
    
    <h4>Project Objectives:</h4>
    <p>The objectives of the Kings Flour Mill project are as follows:</p>
    <ol>
    <li>To build a modern factory that will meet international standards for the production and packaging of high-quality wheat-based flour.</li>
    <li>To achieve a production capacity of 500 metric tons per day, which will cater to the growing demand for flour in the Nigerian market.</li>
    <li>To create job opportunities for the local community and contribute to the economic growth of the region.</li>
    <li>To implement efficient and sustainable processes that will reduce waste and environmental impact.</li>
    </ol>
    <h4>Project Deliverables:</h4>
    <p>The project will deliver the following:</p>
    <ol>
    <li>A fully functional factory with state-of-the-art machines for processing and packaging high-quality wheat-based flour.</li>
    <li>A production capacity of 500 metric tons per day.</li>
    <li>Power generators, water treatment plants, and waste management systems to support the factory's operations.</li>
    <li>Job opportunities for the local community.</li>
    <li> Efficient and sustainable processes that will reduce waste and environmental impact.</li>
    </ol>
    <h4>Conclusion:</h4>
    <p>The Kings Flour Mill project will be a significant milestone in the Nigerian flour industry, providing high-quality wheat-based flour to the market and creating job opportunities for the local community. The implementation of modern technology and efficient processes will ensure that the project is sustainable and contributes to the economic growth of the region.</p>`,
    image_gallery: [],
    start_date: 'July, 2019',
    end_date: 'May, 2021',
    status: 'Completed',
    slug: 'kings-flourmill-factory-project',
    cover: kfmCover,
  },
  {
    id: 3,
    title: 'Odiokwu Road Project',
    image: odiokwu,
    alt: 'Odiokwu',
    url: '#odiokwu',
    completed: false,
    feature: true,
    description: 'CONSTRUCTION OF ODIOKWU INTERNAL ROAD NETWORK, ODIOKWU-OYIGBA ROAD / BRIDGE AND ODIOKWU-AWUNIGBOKO ROAD IN AHOADA-WEST LOCAL GOVERNMENT AREA. This Project of total length 15.7KM was mobilized on 24-11-2018, with the target completion date 15th October 2019. The road stretch cuts across up to seven (3) villages in Ahoada-West L.G.A, Contract was awarded by Bureau for Special Projects as a means of opening doors to industrialization and also creating employment opportunities for the locals and training with the supervision of our Expatriates.',
    image_gallery: [],
    start_date: 'November, 2018',
    end_date: 'August, 2018',
    status: 'On-going',
    slug: 'odiokwu-road-project',
  },
  {
    id: 4,
    title: `Construction of 12,742KM Awa
    Iman-Asong-Ikot Edim-Ikot Emem Road With
    2 Bridges in Onna and Mkpat Enin LGAs`,
    image: phase3,
    alt: 'Phase 3',
    url: '#phase3',
    completed: true,
    feature: true,
    description: `Phase III Road Project, construction of 12.742 Km 
    Ikot-Emem Road with 2NO. Bridges, The project which was mobilized on 
    15th June 2016 to be completed by 30th June 2017. In the project, 2NO. 
    Bridges (35m & 75m Bridges), 6.9KM Main alignment with two spurs 
    (spur 1 1.024KM, spur 2  3.687Km) and 230M Access Road to the Meter Company.`,
    image_gallery: [],
    start_date: 'October, 2015',
    end_date: 'March, 2018',
    status: 'On-going',
    slug: 'onna-road-project-phase-iii',
    cover: phase3Cover,
  },
  {
    id: 5,
    title: 'Construction of Jubilee Syringe Manufacturing Factory',
    image: syringe,
    alt: 'Jubilee Syringe',
    url: '#jsm',
    completed: true,
    feature: true,
    description: `Syringe Factory project has located at Awa village, in 
    Onna Local Government Area of Akwa Ibom State. It has a production area of 
    6000m2, designed and constructed to produce 350million Syringes annually 
    with proposed increased to 1 billion units by 2018. The project consists 
    of warehouse area, office space, generator room, sterile production areas 
    where the injection, scale printing, assembly and packing areas activities 
    are designed to take place. The injection molding Machines were produced and 
    installed by Engel, known to be the best in the market. Ethylene oxide 
    gas would be used to sterilize the syringes. The products to be produced 
    at the factory include 2ml, 5ml and 10ml disposable syringes. Production 
    should start ending of August 2017, once the project is completed by 
    mid-August 2017. It is estimated to employ 150 people when running on full 
    capacity, most of which would be indigenes of the area where the factory is located`,
    image_gallery: [],
    start_date: 'July, 2016',
    end_date: 'October, 2017',
    status: 'Completed',
    slug: 'syringe-factory-construction',
    cover: jsmCover,
  },
  {
    id: 6,
    title: 'St. Gabriel Coconut Oil Factory Project',
    image: coconut,
    alt: 'Coconut Factory',
    url: '#coconut',
    completed: true,
    feature: true,
    description: `St. Gabriel Coconut Oil Factory Project, a major industrial investment by Akwa Ibom State Government through the Ministry of Agriculture and Food Sufficiency, comprises of a factory complex complete with administrative offices, production areas, technical spaces, industrial firefighting systems, water treatment plants, effluent treatment plants and residential quarters for technical and managerial staff as well as parking areas for tractors carrying raw materials. \n

    The project is located in the serene environment of Ikot Akpan Okop village in Mkpat Enin Local Government Area on 7.88 hectares. The total construction area is 15,000 m2. The project was flagged off by His Excellency, Executive Governor of Akwa Ibom State, Mr. Udom Gabriel Emmanuel during a ground-breaking ceremony on May 24th, 2017 and is in progress on track for commissioning in 2018.`,
    image_gallery: [],
    start_date: 'March, 2017',
    end_date: 'October, 2018',
    status: 'Completed',
    slug: 'coconut-factory',
    cover: coconutCover,
  },
  {
    id: 7,
    title: '21 Storey Smart Office Building Project',
    image: tower,
    alt: 'Office Building',
    url: '#tower',
    completed: true,
    feature: true,
    description: `<p>The 21 Storey Smart Office Building Project is a state-of-the-art office complex located in the central business district of Uyo, the Akwa Ibom State capital. The project will sit on a land area of 48,200 square metres and will have a total build area of 18,639 square metres.

    <p>The building's architectural design provides a great landscape view of the city of Uyo, creating an inspiring and conducive environment for the occupants. The building will have 500 parking bays to accommodate the parking needs of the occupants and visitors.</p>
    
    <p>The smart office building will feature four elevators, access control systems, humidity and temperature sensors, and a central cooling system to provide occupants with a comfortable and safe working environment. These systems will also provide efficient energy management and optimal indoor air quality.</p>
    
    <p>The project will incorporate the latest technologies in building automation, including smart lighting systems, water conservation systems, and waste management systems, to ensure efficient building operations and sustainability.</p>
    
    <p>In summary, the 21 Storey Smart Office Building Project is a modern and environmentally sustainable office complex located in the heart of Uyo. The building's design, location, and amenities are tailored to meet the needs of the city's business community, providing a conducive environment for work and leisure.</p>`,
    image_gallery: [],
    start_date: 'June, 2017',
    end_date: 'November, 2019',
    status: 'Completed, Retention and Guarantee Period on-going.',
    slug: '21-storey-office-building-project',
    cover: towerCover,
  },
  {
    id: 8,
    title: `Dualization of 1KM Awa Ikot Nkan Road With
    Outfall Drains in Onna LGA`,
    image: phase1,
    alt: 'phase 1',
    url: '#phase1',
    completed: true,
    feature: false,
    description: `Dualization Of 1 Km Awa Ikot Nkan Road With 
    Outfall Drains In Onna Local Government Area (Phase I)`,
    image_gallery: [],
    start_date: 'September, 2015',
    end_date: 'August, 2017',
    status: 'Completed, Retention and Guarantee Period on-going.',
    slug: 'onna-road-projects-phase-i',
    cover: phase1Cover,
  },
  {
    id: 9,
    title: `Construction of 10.8KM AWA-NKANA ROAD AND 2.3KM 
    ANCILLARY ROADS IN AWA VILLAGE, ONNA L.G.A.`,
    image: phase2,
    alt: 'phase 2',
    url: '#phase2',
    completed: true,
    feature: false,
    description: 'Phase II Road Project : Construction of 10.8KM AWA-NKANA ROAD AND 2.3KM ANCILLARY ROADS IN AWA VILLAGE, ONNA L.G.A.  This project was mobilized on 11th February 2017, with the target completion date 20th October 2018.  The road stretch cuts across up to seven (7) villages in Onna L.G.A,  therefore opening doors to industrialization and also creating employment opportunities for the locals and training with the supervision of our Expatriates.',
    image_gallery: [],
    start_date: 'June, 2016',
    end_date: 'May, 2018',
    status: 'On-going',
    slug: 'phase-ii-road-project',
    cover: phase2Cover,
  },
  {
    id: 10,
    title: 'Hotel Project',
    image: hotelPhc,
    alt: 'hotel phc',
    url: '#hotelphc',
    completed: false,
    feature: true,
    description: `<p>The Hotel Project is a new 5-star hotel located at Tombia Extension Phase II GRA in Port Harcourt. A 4-story building painted in a modern and elegant style.</p>

    <p>The hotel will feature 54 standard guest rooms, 21 suite rooms, and 4 executive suite rooms, each equipped with modern amenities and facilities to ensure a comfortable and luxurious stay for guests.</p>
    
    <p>The hotel will also feature a range of facilities and services, including a restaurant serving international cuisine, a bar, a fitness center, a spa, and a pool. The hotel's conference and event spaces will be equipped with the latest technology and will be able to accommodate a range of business and social events.</p>
    
    <p>Overall, the Hotel Project is an exceptional 5-star hotel designed to provide guests with a luxurious and comfortable stay in the heart of Port Harcourt. With its modern facilities, elegant design, and outstanding services, the hotel is set to become a top destination for business and leisure travelers alike.</p>`,
    image_gallery: [],
    start_date: 'February, 2020',
    end_date: 'June, 2023',
    status: 'On-going',
    slug: 'phc-hotel',
    cover: hotelfcCover,
  },
];

export default Projects;
