import aboutImg from '../images/about_us-150x150.png';
import missionImg from '../images/mission-150x150.png';
import visionImg from '../images/vision-150x150.png';
import qualityImg from '../images/quality-150x150.jpg';

const VksProfiles = [
  {
    id: 1,
    title: 'About Us',
    image: aboutImg,
    desc: `VKS Engineering and Construction CO. is a company incorporated in 
              Nigeria and is part of VKS Group, a global company with over 25 years of experience
              undertaking projects all around the world since 1981.`,
  },
  {
    id: 2,
    title: 'Our Vision',
    image: visionImg,
    desc: `VKS Group’s vision is to bring to life projects
        aimed at supporting sustainable human habitation by
        effectively evaluating all aspects within a project
        framework all over the globe.`,
  },
  {
    id: 3,
    title: 'Our Mission',
    image: missionImg,
    desc: `VKS Group’s mission is to become a forerunner
        amongst worlds’ major engineering, construction
        and project management organizations by delivering
        outstanding results to our clients; Read more`,
  },
  {
    id: 4,
    title: 'Quality',
    image: qualityImg,
    desc: `VKS Construction has established, documented,
        implemented, and maintained a Quality Management
        System to ensure the effectiveness of the business
        and to meet the needs and expectations of … Read more`,
  },
];

export default VksProfiles;
