/* eslint-disable react/prop-types */
import { Container } from 'react-bootstrap';
import PageHelment from '../helmet';

export default function PageLayout({ children, title, className = 'about-section' }) {
  return (
    <div className={`page ${className}`}>
      <PageHelment>
        {`${title} - VKS Nigeria Construction Ltd.`}
      </PageHelment>
      <Container className="page-space">
        {children}
      </Container>
    </div>
  );
}
