import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import PageLayout from '../components/page';
import '../styles/doc.css';

export default function Portfolio() {
  const [numPages, setNumPages] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <PageLayout title="Portfolio" className="mission-section">
      <Document
        file="/vks-portfolio.pdf"
        options={{ workerSrc: '/pdf.worker.js' }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <div>
        <p>
          Page
          {' '}
          {pageNumber || (numPages ? 1 : '--')}
          {' '}
          of
          {' '}
          {numPages || '--'}
        </p>
        <button type="button" className="btn btn-success" disabled={pageNumber <= 1} onClick={previousPage}>
          Previous
        </button>
        <button
          type="button"
          className="btn btn-success"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </button>
      </div>
    </PageLayout>
  );
}
