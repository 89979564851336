import PageHelment from '../components/helmet';
import HomeSlider from '../components/HomeSlider';
import FeatureSection from '../containers/FeatureSection';
import Projects from '../containers/Projects';

function HomePage() {
  return (
    <>
      <PageHelment>
        VKS Nigeria Construction Ltd.
      </PageHelment>
      <HomeSlider />
      <FeatureSection />
      <Projects />
    </>
  );
}

export default HomePage;
