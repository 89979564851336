/* eslint-disable max-len */
import PageLayout from '../components/page';

const AboutPage = () => (
  <PageLayout title="About">

    <h1>About Us</h1>
    <p>
      VKS Nigeria Construction Ltd. is a company incorporated in Nigeria and is part of VKS Construction, a global construction company with over 39 years of experience completing challenging projects around the World since 1981. The Company currently has over 1.000 employees.
    </p>

    <p>
      VKS is proud to have a long reference list of marquee clients. The Company has successfully completed numerous Turnkey projects around the globe including residential, commercial and industrial buildings, highway construction and infrastructure works, factories as well as waste water treatment plants.
    </p>

    <p>
      VKS Group has also invested in two industrial projects, namely Jubilee Syringe Manufacturing Company Ltd and Kings Flour Mill Ltd. Jubilee Syringe Manufacturing Company Ltd. (‟JSM”), founded in 2016, is VKS’ first industrial investment in Akwa Ibom State. JSM is the largest manufacturer of disposable syringes in Africa with a capacity of over 350 million syringes per annum. JSM has become a market leader in the Nigerian syringe manufacturing sector and will commence exporting its products to neighbouring countries in 2021. Kings Flour Mill Ltd. (“KFM”) is VKS’ second industrial investment that became operational at the beginning of 2020. KFM has a daily milling capacity of 500 tons per day and has already become the leading flour brand in the South South region
    </p>

    <p>
      Our advanced Quality Management Systems, Health and Safety Management Systems and Environmental Management Systems are a direct product of our collective experiences and are continuously reviewed and improved.
      VKS’ core values of creativity, integrity, dedication, openness, fairness, and assertiveness are evident throughout our management team and the organization. We encourage open communication and value autonomy within our teams and recognize that our project staff has the required expertise to achieve our objectives.
    </p>
  </PageLayout>
);

export default AboutPage;
