import { Row, Col } from 'react-bootstrap';
import ContactCard from '../components/card/contactCard';
import PageLayout from '../components/page';
import { OFFICES } from '../data';

const OfficePage = () => (
  <PageLayout title="Offices">
    <h1>Our Offices</h1>
    <Row xs={1} md={2} className="g-4">

      {
            OFFICES.map(({
              id, image, title, text,
            }) => (
              <Col key={id}>
                <ContactCard title={title} text={text} image={image} />
              </Col>
            ))
          }
    </Row>
  </PageLayout>
);

export default OfficePage;
