import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  HomePage, AboutPage, MissionPage, PortfolioPage,
  EthicsPage, ProcurementPage, ContactPage,
  LogisticsPage, CompletedProjectPage,
  OngoingProjectsPage, TermsPage, CareerPage, PrivacyPage,
  NewsPage, ProjectDescriptionPage, ManagementPage,
} from './pages';
import Footer from './components/Footer';
import TopNav from './components/topNav';
import MainNav from './components/mainNav';

function App() {
  return (
    <div>
      <TopNav />
      <MainNav />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/mission" component={MissionPage} />
        <Route path="/ethics" component={EthicsPage} />
        <Route path="/procurement" component={ProcurementPage} />
        <Route path="/logistics" component={LogisticsPage} />
        <Route path="/offices" component={ContactPage} />
        <Route path="/completed-projects" component={CompletedProjectPage} />
        <Route path="/ongoing-projects" component={OngoingProjectsPage} />
        <Route path="/terms" component={TermsPage} />
        <Route path="/career" component={CareerPage} />
        <Route path="/privacy" component={PrivacyPage} />
        <Route path="/portfolio" component={PortfolioPage} />
        <Route path="/news" component={NewsPage} />
        <Route path="/management" component={ManagementPage} />
        <Route path="/projects/:slug" component={ProjectDescriptionPage} />
        <Route path="*" render={() => <Redirect to="/" />} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
