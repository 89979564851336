import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
`;

const Card = styled.div`
  width: 250px;
  margin: 12px;
  padding: 12px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  }
`;

const Image = styled.img`
  width: 100%;
  border-radius: 50%;
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 16px;
`;

const Position = styled.div`
  font-size: 16px;
  color: gray;
  margin-top: 8px;
`;

const ManagementTeam = ({ data }) => (
  <Container>
    {data.map(({
      id, image, name, position,
    }) => (
      <Card key={id}>
        <Image src={image} alt={name} />
        <Name>{name}</Name>
        <Position>{position}</Position>
      </Card>
    ))}
  </Container>
);

ManagementTeam.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ManagementTeam;
