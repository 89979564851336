import {
  Container,
} from 'react-bootstrap';
import { socialIcons } from '../data';

export default function Footer() {
  return (
    <footer id="footer-main">
      <div className="footer footer-dark bg-dark">
        <Container>

          <div className="row align-items-center justify-content-md-between py-4 mt-4 delimiter-top">
            <div className="col-md-4">
              <div className="copyright text-sm font-weight-bold text-center text-md-left text-light">
                &copy; 2022. All rights reserved.
              </div>
            </div>
            <div className="col-md-4">
              <div className="copyright text-sm font-weight-bold text-center">
                <a href="/">VKS Nigeria Construction Limited.</a>
              </div>
            </div>
            <div className="col-md-4">
              <ul className="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                { socialIcons.map(icon => (
                  <li className="nav-item" key={icon.title}>
                    <a className="nav-link active" href={icon.url} target="_blank" rel="noreferrer">
                      {icon.name}
                    </a>
                  </li>
                )) }
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
}
