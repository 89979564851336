import { FaTwitter, FaFacebookF, FaLinkedin } from 'react-icons/fa';

const socialIcons = [
  {
    title: 'Twitter',
    name: <FaTwitter fill="#00acee" />,
    url: 'https://twitter.com/VKSConstruction',
  },
  {
    title: 'Facebook',
    name: <FaFacebookF fill="#3b5998" />,
    url: 'https://www.facebook.com/VKSConstruction',
  },
  {
    title: 'LinkedIn',
    name: <FaLinkedin fill="#0077b5" />,
    url: 'https://www.linkedin.com/company/vks-construction-ltd',
  },
];

export default socialIcons;
