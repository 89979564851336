import PageLayout from '../components/page';

const LogisticPage = () => (
  <PageLayout title="Logistics">
    <h2>Logistics</h2>
    <p>
      Logistics is generally the detailed organization and
      implementation of a complex operation. In a general business sense,
      logistics is the management of the flow of things between
      the point of origin and the point of consumption in order to
      meet requirements of customers or corporations.
    </p>

    <p>
      The resources managed in logistics can include physical items
      such as raw materials, tools, spare parts, equipment, and electrical
      and mechanical materials ; as well as abstract items,
      such as time and information. The logistics of physical items
      usually involves the integration of information flow, material
      handling, production, packaging, inventory, transportation,
      warehousing, and usually security. VKS Group is very well
      oriented about Logistics within the  Sub-Sahara African countries.
    </p>
  </PageLayout>
);

export default LogisticPage;
