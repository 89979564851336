import nigeria from '../images/nigeria.png';
import gabon from '../images/gabon.png';
import congo from '../images/congo.jpeg';

const OFFICES = [
  {
    id: 1,
    image: nigeria,
    title: 'SUBSIDIARY – NIGERIA',
    text: 'No 171, Chris Ekpenyong Street, Shelter Afrique Estate, Uyo, Akwa Ibom State +234 703 549 1425',
  },
  {
    id: 2,
    image: gabon,
    title: 'BRANCH  – GABON',
    text: '559 YG2 Angongje Akanda P.O. Box 141129 Libreville +24104004090',
  },
  {
    id: 3,
    image: congo,
    title: 'BRANCH  – REPUBLIC OF CONGO',
    text: '104 Rue Ngoko Brazzaville / Republic of Congo',
  },
];

export default OFFICES;
