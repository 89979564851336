import PageLayout from '../components/page';

const MissionPage = () => (
  <PageLayout
    title="Mission and Vision"
    className="mission-section"
  >

    <h4>Our Vision</h4>
    <p>
      VKS Group’s vision is to bring to life projects
      aimed at supporting sustainable human habitation
      by effectively evaluating all aspects within a
      project framework all over the globe.
    </p>

    <h4>Our Mission</h4>
    <p>
      VKS Group’s mission is to become a forerunner amongst worlds’
      major engineering, construction and project management
      organizations by delivering outstanding results to our
      clients; establishing satisfying and intellectually
      challenging careers for our people.
    </p>

    <h4>Our Values</h4>
    <h6>Health, Safety and Environment</h6>
    <p>
      We strive to provide the utmost in health, safety
      and environmental conditions to ascertain a productive
      working and living environment for everyone.
    </p>

    <h4>Ethics</h4>
    <p>
      We value responsibility, sustainability, discipline,
      innovation, accountability and transparency in all our endeavors.
    </p>
  </PageLayout>
);

export default MissionPage;
