// eslint-disable-next-line import/prefer-default-export
const topMenuLinks = [
  {
    id: 1,
    title: 'Career',
    url: '/career',
    extra_class: 'pr-3',
  },
  {
    id: 2,
    title: 'Terms of Use',
    url: '/terms',
    extra_class: 'pr-3',
  },
  {
    id: 3,
    title: 'Policy',
    url: '/privacy',
    extra_class: 'pr-3',
  },
  {
    id: 5,
    title: 'Contact Us',
    url: '/offices',
    extra_class: '',
  },
];

export default topMenuLinks;
