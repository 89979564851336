import PageLayout from '../components/page';

const TermsPage = () => (
  <PageLayout title="Terms and Conditions">
    <h4>Terms of Use</h4>
    <p>
      VKS Engineering and Construction Co. (the “Company”) endeavours
      to ensure that the information on the website is correct;
      however the Company does not guarantee the accuracy and
      completeness of the material on the website. The Company
      may make changes to the material on the website, or to the
      services described in it, at any time without notice.
    </p>

    <p>
      The material on the website is provided “as is”, without any
      conditions, warranties or other terms of any kind. Accordingly,
      to the maximum extent permitted by law, the Company excludes
      all representations, warranties, conditions and other terms
      including, without limitation, the conditions implied by law
      of satisfactory quality, fitness for purpose and the use of
      reasonable care and skill which for this legal notice,
      might have effect in relation to the website.

    </p>

    <p>
      The information contained on the website is
      not an invitation to invest in the shares,
      or any other products or services or otherwise
      deal in these or enter into a contract with
      the Company or any other company.
    </p>
    <p>
      The past performance of the Company or any other
      company referred to on the website cannot be
      relied upon as a guide to its future performance.
    </p>
    <p>
      Links to third party websites on the website are provided
      solely for your convenience. If you use these links,
      you leave the website. The Company has not reviewed
      all of these third party websites and does not control
      and is not responsible for these websites or their content
      or availability. The Company therefore does not endorse or
      make any representations about them, or any material found
      there, or any results that may be obtained from using them.
      If you decide to access any of the third party websites
      linked to the website, you do so entirely at your own risk.
    </p>
  </PageLayout>
);

export default TermsPage;
