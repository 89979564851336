import coconutSlide from '../images/coconut_slide.jpg';
import flourmillSlide from '../images/flourmill_slide.jpg';
import towerSlide from '../images/banner_tower.jpg';

const slides = [
  {
    id: 1,
    image: towerSlide,
    alt: 'tower building',
  },
  {
    id: 2,
    image: flourmillSlide,
    alt: 'flourmill factory',
  },
  {
    id: 3,
    image: coconutSlide,
    alt: 'coconut factory',
  },
];

export default slides;
