/* eslint-disable quotes */
import tower from '../../images/dakada-tower.jpeg';
import odio from '../../images/odio_insp.jpeg';
import airport from '../../images/airport.jpeg';

const newsData = [
  {
    id: 1,
    title: "VKS Group receives another award for its contribution to Nigeria’s development",
    url: 'https://www.premiumtimesng.com/promoted/503215-vks-group-receives-another-award-for-its-contribution-to-nigerias-development.html',
    excerpt: '"We in VKS Group understand that with such honour comes higher responsibilities,” says the CEO of VKS Group, Onur Kumral.',
    source: 'Premium Times',
    date: 'December 30, 2021',
    author: 'Press Release',
    image: 'https://i1.wp.com/media.premiumtimesng.com/wp-content/files/2021/12/Onur-Kumral-CEO-VKS-Group-e1640864683259.jpg?resize=669%2C568&ssl=1',
    alt: 'Onur Kumral',
    content: `<p>VKS Group, on Friday in Uyo, received one of the most coveted media awards at the 2021 edition of Press Week of the Nigeria Union of Journalists, Akwa Ibom State Council.</p>
    <p>The award – Award of Excellence on meritorious service to the growth of Infrastructure Development in Nigeria – is designed to celebrate persons and organisations who have not only excelled in their fields of endeavour but have contributed to the infrastructure development in the country, including roads and other physical infrastructure.</p>
    <p>The VKS Group, a Turkish business group based in Akwa Ibom State, has as its subsidiaries VKS Nigeria Construction Limited, Jubilee Syringe Manufacturing Company and Kings Flour Mill.</p>
    <p>Besides their contributions to the economic development in Akwa Ibom and Nigeria, the VKS Group is renowned for its consistent support to businesses and groups, including the NUJ, in Akwa Ibom.</p>`,
    fullContent: '',
    feature: true,
  },
  {
    id: 2,
    title: "INTERVIEW: We’re opening Turkish textile industry in Akwa Ibom in 2022 – MD, VKS Group",
    url: 'https://www.premiumtimesng.com/features-and-interviews/479559-interview-were-opening-turkish-textile-industry-in-akwa-ibom-in-2022-md-vks-group.html',
    excerpt: 'Onur Kumral: "I have only three expatriates, the rest are all locals and it would be good when they have the opportunities to open their factories, maybe to support the syringe factory, or they even open a syringe factory."',
    source: 'Premium Times',
    date: 'August 16, 2021',
    author: '',
    image: 'https://i0.wp.com/media.premiumtimesng.com/wp-content/files/2021/08/Onur-Kumral-MD-VKS-Group-e1629123324904.jpg?resize=669%2C521&ssl=1',
    alt: 'Onur Kumral',
    content: `<p>An industrial revolution is underway in Akwa Ibom State, spearheaded by Onur Kumral, a Turkish businessman and investor, who recently won the Independence Newspaper CEO of the Year in Nigeria.</p>
    <p>In this interview with Godwin Akpan, Mr Kumral speaks on manufacturing and business-related issues, including plans to export syringes from Nigeria to Germany.</p>
    <p className="font-weight-bold">Q: This is the very first time a non-Nigerian has been honoured as CEO of the year, during the 2020 Independence Award. This is outstanding. How did you arrive at this, what’s the secret?</p>
    <p>Onur Kumral: It’s our organisation that got the award. We get this as a people. I’m just getting this on behalf of them. First, I really want to thank my team, Akwa Ibom State, stakeholders, and the company, for their support. It (the award) came through hard work. Now, it is time for new frontiers. The secret is that we worked hard. We believe in this country. We believe that if we continue like this, we will get more.</p>
    <p className="font-weight-bold">Q: How are Akwa Ibom people benefiting from your investment in the state?</p>
    <p>Onur Kumral: Akwa Ibom State is one of the largest oil producing States in Nigeria. But unfortunately, in the past, the State could not record adequate investment. That is what we saw as a point of business. So, the things that we created have impacted much on manpower and also trade. When you have investments like the ones we have here, Jubilee Syringe Manufacturing Limited and Kings Flour Mill, you are simply creating jobs for the people.</p>`,
    fullContent: '',
    feature: false,
  },
  {
    id: 3,
    title: "Akwa Ibom Govt hails VKS for delivering 21-floor Dakkada Towers in Uyo",
    url: 'https://www.premiumtimesng.com/promoted/449831-akwa-ibom-govt-hails-vks-for-delivering-21-floor-dakkada-towers-in-uyo.html',
    excerpt: 'The Governor of Akwa Ibom State, Udom Emmanuel, has officially declared open the 21-floor storey building completed and up for lease to high-earned clients.',
    source: 'Premium Times',
    date: 'March 18, 2021',
    author: 'Press Release',
    image: tower,
    alt: 'Dakada Towers',
    content: `<p>The building, also known as the Dakkada Towers, was conceived by Mr Emmanuel’s administration who contracted its construction to a renowned firm, VKS Nigeria Construction Ltd.</p>
    <p>The Governor, at the State Executive Council meeting, Wednesday, in Uyo, announced the completion of the historic project which was delivered to him in December 2020 by VKS Nigeria Construction Limited.</p>
    <p>The smart building, a flagship project of Mr Emmanuel’s administration, is said to be the tallest in Nigeria’s South-south region.</p>
    <p>The Dakkada Towers, according to the contractual agreement between the Akwa Ibom government and the VKS Nigeria Construction Limited, was to be completed in two years and six months.</p>
    <p>But the contractors were able to finish it in less than two years, and thereby eliciting commendation from Governor Emmanuel.</p>
    <p>The Akwa Ibom State Commissioner for Information and Strategy, Ini Ememobong, who spoke with reporters at the Government House, Uyo, after the State Executive Council meeting, said the Council was pleased with the report of completion of work on the 21-floor building.</p>`,
    fullContent: '',
    feature: true,
  },
  {
    id: 4,
    title: "How businesses can tap into VKS contributions to Akwa Ibom economy",
    url: 'https://www.premiumtimesng.com/promoted/448348-how-businesses-can-tap-into-vks-contributions-to-akwa-ibom-economy.html#.YEsdsO8LMEg.twitter',
    excerpt: 'VKS has stationed itself as one of the biggest economic drivers in Akwa Ibom State.',
    source: 'Premium Times',
    date: 'March 12, 2021',
    author: 'Godwin Akpan',
    image: 'https://i1.wp.com/media.premiumtimesng.com/wp-content/files/2021/03/MD-of-VKS-Groups-Mr-Onur-Kumral-e1615532510816.jpg?resize=480%2C387&ssl=1',
    alt: 'Onur Kumral',
    content: `<p>Blessed with abundant natural and human resources, coupled with a peaceful environment, Akwa Ibom State was ready for business alternatives other than civil service and over-dependence on one multinational oil company for its economic turnaround.</p>
    <p>The field was ripe, but the harvesters were few. There was a need for cultivators with foresight.</p>
    <p>A Turkish firm, VKS Group, in 2016, took the bull by the horn and kick-started an investment that would contribute immensely in changing the economic narrative of Akwa Ibom State, fondly called the Promise Land.</p>
    <p>Hitting the ground running was not too difficult owing to the fact that Nigeria is one of Turkey’s largest trade partners in Africa, according to Daily Sabah, a pro-government Turkish newspaper.</p>
    <p>Over the years, VKS Group has successfully completed projects in various parts of the world, some of which are: Nigeria, Russia, Georgia, Turkey, Afghanistan, Uzbekistan and United Arab Emirates. The firm also has active operations and branch offices in Cameroon, Congo, Gabon, Gambia, Ghana, and Libya.</p>`,
    fullContent: '',
    feature: false,
  },
  {
    id: 5,
    title: "Guardian Newspaper honours MD VKS among Top CEOs in 2020",
    url: 'https://www.vanguardngr.com/2021/04/guardian-newspaper-honours-md-vks-among-top-ceos-in-2020/',
    excerpt: "The Managing Director/Chief Executive Officer of VKS Group, Mr Onur Kumral has been honoured among Nigeria’s 100 most proactive, result-driven Chief Executive Officers in 2020.",
    source: 'Gaurdian Newspaper',
    date: 'April 15, 2021',
    author: '',
    image: 'https://cdn.vanguardngr.com/wp-content/uploads/2021/04/VKS.jpeg?width=1080&auto_optimize=medium',
    alt: 'Onur Kumral',
    content: `<p>The honour was bestowed on Kumral by Guardian Newspaper in their 2020 Annual CEO series published on April 7, 2021.</p>
    <p>Kumral was honoured among top CEOs in Nigeria including Ebi Enaholo of Mainstreet Capital Investment, Chantelle Abdu of Mojec International, Gbenga Sokefun of Nigerian Robes among other eminent CEOs.</p>
    <p>Guardian Newspaper explained that what makes the honorees of 2020 edition unique stems from the fact that even before the emergence of the Covid-19 pandemic, Nigeria’s economy was fragile hence caused devastating impact on businesses such as downsizing, laying off staff in order to cut down cost, improve operational capacity and effective delivery on set target.</p>
    <p>The publication pointed out that despite the daunting challenges and dire outcomes on many businesses, the honorees were able to navigate their organizations to optimal operational effectiveness, efficiency and visibility.</p>
    <p>The Guardian said the CEOs made this possible through ingenious management practices, prudent management of resources, as well as excellent, proactive, and innovative solutions and ideas in achieving set goals and objectives.</p>
    <p>The newspaper noted ” While they defy the apparent harsh economic environment by piloting the affairs of their companies to profitability, these exceptional men and women deserve recognition for their out-of the-box innovations and enviable CSR interventions. Indeed, they deserve laurels in their efforts and contributions to the stability and growth of the nation’s economy as well as modeling other upcoming businesses.”</p>
`,
    fullContent: '',
    feature: false,
  },
  {
    id: 6,
    title: "Construction firm mobilizes workers, equipment to site on 15.1KM Odio Road",
    url: '#/',
    excerpt: 'A construction firm in Akwa Ibom State, VKS Nigeria Construction Company, has mobilized more personnel and equipment to the 15.1KM Odio Road in Eket Local Government Area of Akwa Ibom State.',
    source: 'FLON News',
    date: 'February 11, 2021',
    author: 'Abasifreke Obot',
    image: odio,
    alt: 'Odio Road',
    content: `<p>This development is sequel to the recent inspection of the road project by the Commissioner for Works in the state, Mr. Ephraim Inyang-eyen, where he expressed dissatisfaction at the slow pace of work embarked by the firm.</p>
    <p>The commissioner had noted that the construction firm ought to mobilize more personnel and equipment to the project site to help expedite work, adding that the government of Udom Emmanuel was not ready to leave any abandoned project beyond his administration, hence, the need for speedy completion of all ongoing projects in the state.</p>
    <p>Similarly, VKS Nigeria Construction Company has reinforced its operations by engaging more manpower and adequate machines on the project site to meet the stipulated timeline for the Odio road project.</p>
    <p>A visit to the project site by INK reporters confirmed that work has been intensified. Heavy duty equipment were seen hovering around the construction site despite the light morning shower. A good number of workers; both indigenous and expatriates were seen working on the road.</p>
    <p>Speaking on behalf of the management, Mr. Ndiana Isong, said: “As you can see, on this Odio 15.1KM road project, work is going on a high velocity. VKS as a company is determined to deliver the best for the state and the people of the state.</p>`,
    fullContent: '',
    feature: true,
  },
  {
    id: 7,
    title: "Odio/Abat Road Construction Is Progressively On Track, VKS Assures",
    url: 'https://nationaleconomy.com/news/odio-abat-road-construction-is-progressively-on-track-vks-assures/',
    excerpt: 'VKS Nigeria Construction Limited has assured that they are on track to complete the construction of Odio- Nditia road in Eket and also the Secretariat road in Abat awarded to the company.',
    source: 'National Economy',
    date: 'February 16, 2021',
    author: '',
    image: 'https://i0.wp.com/nationaleconomy.com/wp-content/uploads/2021/03/WhatsApp-Image-2021-03-09-at-14.45.35-1.jpeg?resize=600%2C375&ssl=1',
    alt: 'Odio Road',
    content: `<p>According to the Human Resources Manager of the Company, Mr Ndiana Isong in a statement issued to newsmen on Monday, he noted that
    VKS equipment and workers are currently on site working on the said projects.</p>
    <p>This comes on the heels of reports making the rounds that the company were delaying in handling the construction of the Odio- Nditia road in Eket and also the Secretariat road in Abat.</p>
    <p>The statement by the company notes “We have a commitment to deliver the road projects and we will deliver as promised.”</p>
    <p>The Construction Company explained that the short delay experienced is as a result of negotiation with the community for right of way, compensation for structures marked for demolition.</p>
    <p>VKS said despite the task, on their part, the company is on track to deliver.</p>
    <p>The company noted that the people of the community are part of the manpower working on the project hence they do not have issues with the community.</p>`,
    fullContent: `<p>A construction firm in Akwa Ibom State, VKS Nigeria Construction Company, has mobilized more personnel and equipment to the 15.1KM Odio Road in Eket Local Government Area of Akwa Ibom State. <p/>
    <p>This development is sequel to the recent inspection of the road project by the Commissioner for Works in the state, Mr. Ephraim Inyang-eyen, where he expressed dissatisfaction at the slow pace of work embarked by the firm. <p/>
    <p>The commissioner had noted that the construction firm ought to mobilize more personnel and equipment to the project site to help expedite work, adding that the government of Udom Emmanuel was not ready to leave any abandoned project beyond his administration, hence, the need for speedy completion of all ongoing projects in the state. <p/>
    <p>Similarly, VKS Nigeria Construction Company has reinforced its operations by engaging more manpower and adequate machines on the project site to meet the stipulated timeline for the Odio road project. <p/>
    <p>A visit to the project site by INK reporters confirmed that work has been intensified. Heavy duty equipment were seen hovering around the construction site despite the light morning shower. A good number of workers; both indigenous and expatriates were seen working on the road. <p/>
    <p>Speaking on behalf of the management, Mr. Ndiana Isong, said: “As you can see, on this Odio 15.1KM road project, work is going on a high velocity. VKS as a company is determined to deliver the best for the state and the people of the state. <p/>
    <p>“The government of Udom Emmanuel has done its part as it concerns mobilization for the project, and we as a company are poised to delivering quality job and on real time, especially on this project. </p>
    <p> “The little delay you noticed was due to the heavy downpour we all witnessed in the last past few months, which obviously slowed down construction work. Now that the rain has subsided, we have moved into our project sites with even more intensity. <p/>
    <p> “Our Company has an excellent reputation for delivering quality jobs, not only in Akwa Ibom State, but in other states of Nigeria. We are committed to this project. We are committed to Akwa Ibom project and we are committed to making sure that the governor’s completion agenda is a reality. This is our commitment. <p/>
    <p>Mr Isong thanked the people of Ndoneyo and Ikot Akpatek in Onna Local Government Area, and the people of Odio in Eket, as well as adjoining communities for their support and cooperation to the company. He commended the community leaders, youth leaders, women and everyone living in the communities for providing a conducive atmosphere for VKS staff and promised to continually provide maximum support for the people of the area in any area way possible. <p/>
    <p>Speaking on behalf of the youths of the community, Mr. Boniface Ukpa thanked Governor Udom Emmanuel for the timely intervention of the road. He also expressed satisfaction at the level of work that is ongoing at the moment. He said that the youths of the area will continue to be law abiding, cooperative and provide a conducive working environment for the contractors for the smooth completion of the project. <p/>
    <p>Chief Ufot Etokaffia Nda, a community leader in the area expressed delight at the cooperation the Construction firm has shown the people of the community, especially in the area of providing employment to the youths through the road project. He thanked the governor for his commitment to the wellbeing of the people of the area, the good people of Eket, and indeed residents of the state. <p/>
    <p> “Let me, on behalf of my people, thank governor Udom Emmanuel for awarding the construction of this road. This road has remained in a very bad condition right from time immemorial and as such retarded the progress of this area. The abandonment we faced before now as a result of the poor state of infrastructure in this part of the state could best be seen rather than explain, but we thank governor Udom Emmanuel for bringing light to us and ultimately, giving our youths employment through the awarding of this contract. <p/>
    <p> “Most of the workers here are our children and this has given us a sense of belonging. When completed, this road will ease the movement of goods and services, as well as free flow of traffic. <p/>
    <p> “VKS Construction has been very cooperative. Work is still going on, although it slowed down during the raining season. But we are happy they have resumed work on the project. Hopefully, we are expecting this project to be ready for commissioning anytime soon. And very importantly, the firm has created employment opportunity for our children. We are very happy to have VKS in our community. <p/>
    <p>It is on record that VKS construction company has over the years in Akwa Ibom State delivered excellent jobs especially in the area of road construction and other physical infrastructures, including the 21 stories smart-intelligent-building currently on construction in Uyo, the state capital. <p/>
    `,
    feature: true,
  },
  {
    id: 8,
    title: "VAIA Terminal: Udom poised to make the difference in Nigeria's Aviation Sector",
    url: 'https://thehill.com.ng/vaia-terminal-udom-poised-to-make-the-difference-in-nigerias-aviation-sector/',
    excerpt: 'The 3-storey building sits on a total area of about 23,000m, spanning 183m in length, 117m in width and a height of 14.5m.',
    source: 'The Hill',
    date: 'December 7, 2021',
    author: 'Godwin Akpan',
    image: airport,
    alt: 'Airport Terminal',
    content: `<p>The ongoing construction and remodeling of Victor Attah International Airport (VAIA) in Akwa Ibom State by Governor Udom Emmanuel's administration, has forced huge reactions from people both within the state and beyond. Governor Emmanuel had noted that upon completion, the airport terminal will not only create more employment for the citizens of the state, it will also improve the internally generated revenue, add value to the state and help turn the state into a choice destination for Aviation Development and investment.</p>
    <p>The Governor's decision to invest in this sector is sequel to his determination to turn the economic fortune of the state around. The aviation sector the world over is embedded with so much socio-economic benefits, yet no significant investment has been witnessed in this sector in any part of the country. This no doubt explain why Udom Emmanuel's administration has aggressively pursue the development of the sector to the benefit of both the state and the country in view of turning the state into Nigeria's aviation hub. </p>
    <p>When completed,  the Victor Attah International Airport will become one of the few airports in Africa with latest high-tech equipment and facilities. It will further provide for maximum comfort and safety for airlines companies and air users. This development will effectively and efficiently carter for the growing freight traffic for both Domestic and International flight operations with anticipated dominance of the National, Regional and Continental airspace. </p>
    `,
    fullContent: `<p>The ongoing construction and remodeling of Victor Attah International Airport (VAIA) in Akwa Ibom State by Governor Udom Emmanuel's administration, has forced huge reactions from people both within the state and beyond. Governor Emmanuel had noted that upon completion, the airport terminal will not only create more employment for the citizens of the state, it will also improve the internally generated revenue, add value to the state and help turn the state into a choice destination for Aviation Development and investment.</p>

    <p>The Governor's decision to invest in this sector is sequel to his determination to turn the economic fortune of the state around. The aviation sector the world over is embedded with so much socio-economic benefits, yet no significant investment has been witnessed in this sector in any part of the country. This no doubt explain why Udom Emmanuel's administration has aggressively pursue the development of the sector to the benefit of both the state and the country in view of turning the state into Nigeria's aviation hub. </p>
    
    <p>When completed,  the Victor Attah International Airport will become one of the few airports in Africa with latest high-tech equipment and facilities. It will further provide for maximum comfort and safety for airlines companies and air users. This development will effectively and efficiently carter for the growing freight traffic for both Domestic and International flight operations with anticipated dominance of the National, Regional and Continental airspace. </p>
    
    <p>Handled by a Turkish Construction firm, VKS Nigeria Construction Limited and currently at the roofing stage, the aesthetically designed building is gradually taking the expected shape with strict compliance to International Aviation standards, monitored by International bodies; the International Air Transport Association (IATA) and the International Civil Aviation Organization (ICAO). </p>
    
    <p>The 3-storey building sits on a total area of about 23,000m, spanning 183m in length, 117m in width and a height of 14.5m. It is billed to house the International and domestic wings with the ground floor serving as the Check-in Concourse, the first floor will be for arrival while the second floor will be the departure area. It will have four escalators, seven lifts and staircases for ease of movement within the facility. In addition, it will have six boarding bridges with each having a lift and staircase. It is expected to commence operation with one Code-F and four Codes-C stands on the Apron, 5 Air Bridges and a Bus Gate to serve remote stands. </p>
    
    <p>The terminal will serve dual purpose for domestic and international passengers, allow direct flight connection amongst states and major destinations around the world. It has the capacity to handle future demands of passengers with a flexible and adaptable framework that can respond to the increase in Air Traffic Movement (ATM) per hour within the next 50 years forecasted to reach a maximum of 1,166,045 movements by 2030. Additionally, It will provide conducive atmosphere that would ease the stress of purchasing tickets, check-in of luggages, security checks, comfortably waiting area before departure or arrival and provide auxiliary facilities for travel related issues. </p>
    
    <p>The Terminal also comes with features such as; in- and- out gates, arrival lounge, departure lounge, security clearance point, baggage check-in area, baggage carousels, well built internal road network, parking lots, signs, fire station, motel, customs and Immigration desk-points. Other features are baggage reclaim hall, outbound baggage hall, shops and snack bars, conveniences, supporting office spaces and  beverage lounges among other pertinent features. </p>
     
    <p>Conducting newsmen around the construction site, the Honourable Commissioner for Special Duties, Okpolupm Etteh, said when completed, the terminal will position the state as a destination, boost economic fortune of the state and boost tourism. He noted that Governor Udom Emmanuel's administration upon completion of its tenure, would have left behind an economy that will sustain the state, the country and the African continent, advancing that the Maintenance, Repairs and Overhaul - MRO section of the airport is expected to service airlines across the continent. </p>
    
    <p>Etteh, commended VKS Nigeria Construction Limited for being professional in handling the project and adopting world best practice in service delivery. He solicited that the same way the company delivered the 21 Smart Storey building to the government of the state in record time, the company should do so with the terminal building. He assured the company of government support for the smooth completion of the gigantic project. </p>
    
    <p>A representative of the company, Mr. Ndiana Isong, who is the Group Human Resources Manager, said, VKS will leave no stone unturned in delivering the project to the delight of the government and good people of the state. He assured that VKS has reputation of quality work delivery over the years through the quality of personnel in its employ and equipment. Mr. Isong relied on the huge experience of the construction firm to get the job done and to an international standard. </p>
    
    <p>Isong thanked the host communities for their cooperation so far. He observed that without the cooperation of the host communities, the successes recorded so far wouldn't have been possible. He urged the community leaders to continue to cooperate with the government and the construction firm, as upon completion of the project, they the people will be the greatest beneficiaries, through employment and other benefits. </p>
    
    <p>Reacting on behalf of Okobo community, Mr. Charles Asuquo Ekpenyong, commended the state government for its vision in creating an economy that will change the fortune of the people of the state, and thank the governor for his sincerity and commitment in delivering the job before the expiration of his administration. He promised to continue in the cordial relationship between the host communities and the construction firm, VKS Nigeria. He said the construction firm are doing a great job both in human management and job delivery, hence the overwhelming cooperation. </p>
    
    <p>On his part, the community representative of Uruan, Mr. Raphael Effiong Ndem, said the project upon completion will boost the economy of the country, the state and the local people who are predominantly farmers. He observed that with the full operation of the terminal, their farm produce will be patronized by the influx of guests expected to do business in the state through the airport. He said aside the expected patronage from visitors, their children will be employed in the facility and that will automatically translate into economic fortune for his people. </p>
    
    <p>Mr. Ndem thank Governor Udom Emmanuel for funding the project and thank VKS Nigeria for creating employment for his skilled people who are part of the ongoing construction work. </p>
    
    <p>For Mr. Archibong Prinus Archibong, the days of abandoned projects are over in the state. He said, when the governor flagged off the project, he doubted the possibility of the governor completing the project before the expiration of his tenor. He noted that with what is on ground, he is very delighted and convinced that the governor will not only commission the project on the stipulated time, he will hand over a fully functional airport terminal to the next government. </p>
    
    <p>Archibong commended VKS NIGERIA for the work done so far, and urged the construction firm to speed up with the furnishings so that before the end of 2022 the project will be delivered to the state government and its people for effective use. </p>
    `,
    feature: true,
  },
];

export default newsData;
