import PageLayout from '../components/page';

const ProcurementPage = () => (
  <PageLayout title="Procurement">
    <h2>Procurement</h2>
    <p>
      We are ready to manage any company’s supply of products and services.
      Our feeling of responsibility includes strategizing to find
      cost-effective deals and suppliers. Our duty is to discover
      the best ways to cut procurement expenses, so that any
      company or establishment can invest in growth and people.
      We know the best practice to negotiate with external vendors
      to secure advantageous terms, upon our experience we can
      foresee alterations in the comparative negotiating ability of
      suppliers and clients. We are ready to perform risk management
      for supply contracts and agreements
    </p>

  </PageLayout>
);

export default ProcurementPage;
