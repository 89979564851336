import React from 'react';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

export default function FeaturedProjectItem({
  // eslint-disable-next-line react/prop-types
  title, image, alt, url,
}) {
  return (
    <motion.li
      whileHover={{
        translateY: -1,
        scale: 1.1,
      }}
      transition={{ type: 'spring', stiffness: 300 }}
    >
      <img src={image} alt={alt} loading="lazy" />
      <NavLink to={`projects/${url}`} role="button">{title}</NavLink>
    </motion.li>
  );
}
