import NewsList from '../components/news';
import PageLayout from '../components/page';
import newsData from '../data/news';

export default function NewsPage() {
  const newsInfo = newsData.sort((a, b) => (Date.parse(b.date) - Date.parse(a.date)));

  return (
    <PageLayout title="News">
      {
            newsInfo.map(news => (news.feature
              && (
              <NewsList
                key={news.date}
                title={news.title}
                excerpt={news.excerpt}
                source={news.source}
                date={news.date}
                author={news.author}
                image={news.image}
                alt={news.alt}
                url={news.url}
                content={news.content}
              />
              )
            ))
          }
    </PageLayout>
  );
}
